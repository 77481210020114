import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import pxOrCssValue from '@core/utils/pxOrCssValue';

import classes from './style.module.scss';

interface Props {
  className?: string;
  /**
   * Pass a number for px or a string for a CSS value
   */
  height?: number | string;
  /**
   * Controls the shape variant. Default is `text`.
   */
  kind?: 'box' | 'circle' | 'text';
  style?: React.CSSProperties;
  /**
   * Pass a number for px or a string for a CSS value
   */
  width?: number | string;
}

const Skeleton = ({ className, height, kind = 'text', width, style }: Props) => {
  const bem = useClassy(classes, 'Skeleton');
  const styles = useMemo(
    () =>
      ({
        ...(style && style),
        ...(height && { '--Skeleton-height': pxOrCssValue(height) }),
        ...(width && { '--Skeleton-width': pxOrCssValue(width) }),
      }) as React.CSSProperties,
    [height, style, width],
  );

  return <div className={bem('&', `_${kind}`, className)} style={styles}></div>;
};

export default React.memo(Skeleton);
