import PropTypes from 'prop-types';
import React from 'react';

class Tab extends React.Component {
  static propTypes = {
    activeTab: PropTypes.any.isRequired,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelOverride: PropTypes.node,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  onKeyDown = e => {
    const { label, onClick } = this.props;

    if (e.key === 'Tab') return;
    onClick(label);
  };

  render() {
    const {
      onClick,
      onKeyDown,
      props: { activeTab, label, labelOverride },
    } = this;

    let className = 'Tabs-listItem';
    if (activeTab === label) className += ' Tabs-listItem_active';

    return (
      <li
        aria-label={label}
        aria-selected={activeTab === label}
        className={className}
        onClick={onClick}
        onKeyDown={onKeyDown}
        role="tab"
        tabIndex={onClick ? 0 : -1}
      >
        {labelOverride || label}
      </li>
    );
  }
}

export default Tab;
