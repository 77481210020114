import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';

import classes from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  /**
   * Control the width of each child by passing a string of track sizing values
   * like you would for the `grid-template-columns` css attribute
   */
  columnLayout?: string;
  /**
   * Show or hide vertical rules between each child in the group
   */
  separators?: boolean;
  /**
   * Controls height of the group, passing the `size` down to child Input and Button components
   */
  size?: 'lg' | 'md' | 'sm';
}

function InputGroup({ children, className, columnLayout, separators = true, size = 'md' }: Props): JSX.Element {
  const bem = useClassy(classes, 'InputGroup');
  const style = useMemo(
    () => ({ gridTemplateColumns: columnLayout, '--InputGroup-size': `var(--button-${size})` }),
    [columnLayout, size],
  );

  return (
    <div className={bem('&', !separators && '_no-separators', className)} data-testid="input-group" style={style}>
      {React.Children.map(children, (child: React.ReactNode) => {
        if (React.isValidElement(child)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return React.cloneElement(child as React.ReactElement<any>, {
            size,
          });
        }
        return child;
      })}
    </div>
  );
}

export default InputGroup;
