import type { NextPageLink } from '@readme/backend/models/page/types';

import React, { memo, forwardRef, useCallback } from 'react';

import classy from '@core/utils/classy';

import Box from '@ui/Box';
import Menu, { MenuItem } from '@ui/Menu';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

type MenuItemClickHandler = (result: NextPageLink) => void;

type ItemProps = NextPageLink & {
  onClick?: MenuItemClickHandler;
  selected?: boolean;
};

export const PageMenuItem = ({ category, name, onClick: onClickHandler, selected, slug, type }: ItemProps) => {
  const onClick = useCallback(
    () => onClickHandler?.({ name, slug, type, category }),
    [category, name, onClickHandler, slug, type],
  );
  const className = classy(classes.PageMenuItem, selected && 'selected');

  return (
    <MenuItem
      key={`${name}-${type}:${slug}`}
      aria-current={selected}
      className={className}
      data-name={name}
      onClick={onClick}
      role="menuitem"
    >
      <div className={classes['PageMenuItem-Container']}>
        <Tooltip content={name} delay={[300, 200]} placement="top-start" title>
          <p className={classes['PageMenuItem-Container-name']}>{name}</p>
        </Tooltip>
        <Tooltip content={category} delay={[300, 200]} placement="top-start" title>
          <p className={classes['PageMenuItem-Container-category']}>{category}</p>
        </Tooltip>
      </div>
    </MenuItem>
  );
};

export interface Props {
  children?: React.ReactNode;
  className?: string;
}

// Menu Content (container)
const PageMenu = ({ className, children }: Props, ref: React.ForwardedRef<HTMLElement>) => {
  return (
    <Box className={classy(classes['PageMenu-Container'], className)} kind="pop">
      <Menu ref={ref} className={classes['PageMenu-Menu']} data-testid="link-menu" role="menu">
        {children}
      </Menu>
    </Box>
  );
};

export default memo(forwardRef(PageMenu));
