import { useState, useEffect } from 'react';

const useMediaQuery = query => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mq = window.matchMedia(query);
    setMatches(!!mq.matches);
    mq.onchange = e => setMatches(!!e.matches);
  }, [query]);

  return matches;
};

export default useMediaQuery;
