import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import useUniqueId from '@core/hooks/useUniqueId';

import '../style.scss';

interface Tab {
  label: string;
  to: string;
}

interface TabsNavProps {
  tabs: Tab[];
}

function TabsNav({ tabs }: TabsNavProps) {
  const uid = useUniqueId('tabs-nav');
  let { url } = useRouteMatch();
  url = url.replace(/\/$/, '');

  return (
    <nav className="Tabs Tabs-list">
      {tabs.map(({ label, to }) => (
        <NavLink key={uid(label)} activeClassName="Tabs-listItem_active" className="Tabs-listItem" to={`${url}${to}`}>
          {label}
        </NavLink>
      ))}
    </nav>
  );
}

export default TabsNav;
