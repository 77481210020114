import { getPlanFeature } from '@readme/iso';

/**
 * This function specifies what features each plan has access to.
 * @param  {String} plan       A type of plan. One of free|startup|business|startup2018.
 * @param  {String} permission A feature. One of members|landing_page|stylesheet|javascript.
 * @return {?}                 Varies depending on how many parameters are passed down.
 */
export default function usePlanPermissions(plan, permission) {
  return getPlanFeature(plan, permission);
}
