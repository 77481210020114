import type { ProjectSchema } from '@readme/backend/models/project/types';
import type { PlanConfig, PlanConfigKey, PlanDetails, PlanFeatureKey, PlanFeatures } from '@readme/iso/src/project';
import type { RequiredDeep } from 'type-fest';

import { getFeaturePlan, getPlanFeature, getProjectPlan, isTrialEnabled, PLAN_NAME } from '@readme/iso/src/project';
import { useCallback, useContext, useMemo } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';

/**
 * Hash map of `Project` section modules to `Plan` features to easily look up
 * the plan feature associated to the project module.
 */
const projectModuleFeatureMap = {
  changelog: 'changelogs',
  custompages: 'custom_pages',
  discuss: 'discussions',
  docs: 'guides',
  examples: '',
  graphql: '',
  landing: 'landing_page',
  reference: 'reference',
  suggested_edits: '',
  tutorials: 'recipes',
} satisfies Record<keyof ProjectSchema['modules'], PlanFeatureKey | ''>;

/**
 * Stub out a mock plan detail hash map that we can use to perform O(1) lookups
 * to determine if a particular key is a plan detail key or not.
 */
const planDetailMap = {
  cost: 0,
  createdAt: new Date(),
  endDate: new Date(),
  is_active: false,
  members: 0,
  name: '',
} satisfies RequiredDeep<PlanDetails>;

/**
 * Determines if the provided plan config key is a plan detail key or not.
 */
function isPlanDetail<Key extends PlanConfigKey | string>(key: Key) {
  return key in planDetailMap;
}

/**
 * Returns plan information for the current project like whether the plan is
 * within an active trial window or to determine what plan features are enabled.
 *
 * Plan details include general info like `cost`, `endDate` or `members`. Plan
 * features are mostly `boolean` values that indicates whether a particular
 * feature like `guides`, `glossary`, `html`, etc is supported or not.
 *
 * @example
 * ```ts
 * // Determine if plan override or trial mode is set.
 * const { details, features, isTrial, planOverride } = useProjectPlan();
 * const isPlanOverrideEnabled = !!planOverride;
 *
 * // Find out whether a plan is active and when the end date is nearing.
 * const { is_active: isActive, endDate } = details;
 *
 * // Find out whether custom CSS & HTML should be enabled.
 * const { stylesheet: hasCustomCss, html: hasCustomHtml } = features;
 */
export default function useProjectPlan() {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const isTrial = isTrialEnabled(project);
  const activePlan = getProjectPlan(project);
  const planConfig = getPlanFeature(activePlan);

  /** In trial mode, this patches plan config features to be enabled. */
  const applyTrial = useCallback(
    <T extends PlanConfigKey>(feature: PlanConfig[T]) => {
      return isTrial && typeof feature === 'boolean' ? true : feature;
    },
    [isTrial],
  );

  const { details, features } = useMemo(() => {
    return Object.entries(planConfig).reduce(
      (config, [key, value]) => {
        const slot = isPlanDetail(key) ? 'details' : 'features';
        const patchedValue = isPlanDetail(key) ? value : applyTrial(value);

        return {
          ...config,
          [slot]: {
            ...config[slot],
            [key]: patchedValue,
          },
        };
      },
      { details: {} as PlanDetails, features: {} as PlanFeatures },
    );
  }, [applyTrial, planConfig]);

  return {
    /**
     * Project plan that is currently active. This plan is what's used to lookup
     * plan config values and features. This plan may be different than the plan
     * that is actually assigned, like when a plan override exists.
     */
    activePlan,

    /**
     * Determines whether a project's section module (`landing`, `docs`, etc)
     * is enabled by the current plan or trial.
     */
    isProjectModuleEnabled: useCallback(
      (module: keyof ProjectSchema['modules']) => {
        const feature = projectModuleFeatureMap[module];
        return !!features[feature];
      },
      [features],
    ),

    /**
     * Whether project is in an active trial state or not. Determines this by
     * evaluating more than just trial period but also whether the project is
     * activated and on a free plan.
     */
    isTrial,

    /**
     * Project plan that is currently assigned. This is not the plan that is
     * used to derive plan config values and features. See `activePlan` instead.
     */
    plan: project.plan,

    /**
     * Plan config details like `cost`, `members`, `name`, etc.
     */
    planDetails: details,

    /**
     * Plan config features like `guides`, `glossary`, `html`, etc. mapped to
     * `boolean` values that indicates whether the feature is enabled for this
     * plan. When `isTrial` is enabled, all plan features are overridden to be
     * enabled by default.
     */
    planFeatures: features,

    /**
     * Project plan override. If set, it means the currently assigned `plan` is
     * being overridden. See `activePlan` for what's currently active.
     */
    planOverride: project.planOverride || undefined,

    /**
     * Determines whether the provided plan feature is disabled but is in an
     * active trial mode that temporarily allows the feature to be enabled. When
     * this returns `true` for a feature, a trial badge or banner should be
     * displayed alongside it.
     */
    showTrialIndicator: useCallback(
      (feature?: PlanFeatureKey) => {
        if (!feature) return false;

        // If the feature belongs to the startup plan, we don't want to show the
        // trial indicator. We decided to do this to avoid cluttering the UI
        // with badges during a trial.
        const isStartupFeature = getFeaturePlan(feature) === PLAN_NAME.STARTUP_2018;
        return isTrial && !isStartupFeature;
      },
      [isTrial],
    ),
  };
}
