import React, { useCallback, useState } from 'react';

import './style.scss';
import Tab from './Tab';

interface Props {
  children: [React.ReactElement, ...React.ReactElement[]];
  className?: {
    root?: string;
    tabs?: string;
  };
  /**
   * Callback fired when the tab is changed. Contents passed to this method is the (new) current tab.
   */
  onClick?: (label: string) => void;
}

const Tabs: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { children, className = { root: '', tabs: '' }, onClick },
  ref,
) => {
  const [activeTab, setActiveTab] = useState<string>(children[0].props['data-label']);

  const activate = useCallback(
    tab => {
      onClick?.(tab);
      setActiveTab(tab);
    },
    [onClick],
  );

  return (
    <div ref={ref} className={`Tabs ${className.root || ''}`}>
      <ol className={`Tabs-list ${className.tabs || ''}`} role="tablist">
        {children.map(child => {
          const { 'data-label': label, 'data-label-override': labelOverride } = child.props;

          return (
            <Tab
              key={label}
              activeTab={activeTab}
              className="Tabs-listItem"
              label={label}
              labelOverride={labelOverride}
              onClick={activate}
            />
          );
        })}
      </ol>
      {children.map(child => child.props['data-label'] === activeTab && child.props.children)}
    </div>
  );
};

export { default as TabsNav } from './TabsNav';
export default React.forwardRef<HTMLDivElement, Props>(Tabs);
