import React from 'react';

import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  icon?: string;
}

const InfoPop = ({ children, className, icon = 'icon-help-circle' }: Props) => {
  return (
    <Tooltip className={classes['InfoPop-tooltip']} content={children} interactive>
      <i aria-label="Information" className={`${classes['InfoPop-helpIcon']} ${icon} ${className}`} role="button" />
    </Tooltip>
  );
};

export default React.memo(InfoPop);
