const context = require.context('./', false, /\.(svg|gif)$/);
const fileNames = context.keys();

export type GraphicsMap = Record<
  string,
  {
    ext: string;
    index: number;
    name: string;
    path: string;
  }
>;

/**
 * Returns a hash map of all SVG files in the `core/graphics/**` directory along
 * with some metadata like file name, path and index.
 */
const fileMap = fileNames.reduce<GraphicsMap>((prev, curr, index) => {
  const [path, name, ext] = curr.match(/\/([a-z][a-z0-9-]*)\.([^.]+)$/) || [];

  return path
    ? {
        ...prev,
        [name]: {
          index,
          name,
          path: path.substring(1),
          ext,
        },
      }
    : prev;
}, {});

export default fileMap;
