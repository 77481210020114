import fuzzysort from 'fuzzysort';
import { useMemo } from 'react';
import xss from 'xss';

interface Options {
  highlight?: boolean;
}

function useFuzzySearch<T>(
  targets: T[],
  filter = '',
  options: Fuzzysort.KeyOptions & Options = { key: 'label', highlight: true },
): [T[], string[]] {
  return useMemo(() => {
    if (filter) {
      const results = fuzzysort.go(filter, targets, { key: options.key });
      return results.reduce<[T[], string[]]>(
        ([next, highlighted], result) => {
          const { obj } = result;
          // Create a new object to avoid mutating the original one
          next.push({ ...obj });
          if (!options.highlight) return [next, highlighted];
          // Fuzzy sort will insert <b> tags around the matching text. Be extra safe with xss and strip out any html that's not a <b> tag given we're going to render this as html.
          const allowList = { b: [] };
          const highlightedName = xss(fuzzysort.highlight(result) || '', { whiteList: allowList });
          highlighted.push(highlightedName);
          return [next, highlighted];
        },
        [[], []],
      );
    }

    return [targets, []];
  }, [targets, filter, options]);
}

export default useFuzzySearch;
